var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{attrs:{"title":_vm.title}}),_c('v-card',{staticClass:"mx-auto my-12 mt-2 mb-2",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',[_c('v-row',{staticClass:"pr-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"10","align":"left"},on:{"click":function($event){_vm.showFilters = true}}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplyed}}):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2","align":"right","align-self":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();_vm.showFilters = !_vm.showFilters}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"6","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.tiposAccionesItems,"label":"Tipo de acción","item-text":"value","item-value":"id","outlined":"","dense":"","clearable":"","return-object":""},model:{value:(_vm.tipoAccionSelected),callback:function ($$v) {_vm.tipoAccionSelected=$$v},expression:"tipoAccionSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"6","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.tiposConfiguracionItems,"label":"Tipo de configuración","item-text":"value","item-value":"id","outlined":"","dense":"","disabled":_vm.$route.params.tipoConfigId != null,"clearable":"","return-object":""},model:{value:(_vm.tipoConfiguracionSelected),callback:function ($$v) {_vm.tipoConfiguracionSelected=$$v},expression:"tipoConfiguracionSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"3"}},[_c('v-menu',{ref:"fecha-desde",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha desde","append-icon":_vm.calendarIcon,"autocomplete":"not","hint":"Formato DD/MM/AAAA","outlined":"","dense":"","clearable":"","rules":_vm.rules.required.concat(
                            _vm.rules.validDate,
                            new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                              new Date(_vm.parseDateToIso(_vm.fechaHastaSelected)) ||
                              'Formato incorrecto'
                          )},on:{"blur":function($event){_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeSelected)}},model:{value:(_vm.fechaDesdeSelected),callback:function ($$v) {_vm.fechaDesdeSelected=$$v},expression:"fechaDesdeSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaDesde),callback:function ($$v) {_vm.menuFechaDesde=$$v},expression:"menuFechaDesde"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaDesdeSelected = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menuFechaDesde = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"3"}},[_c('v-menu',{ref:"fecha-hasta",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha hasta","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","outlined":"","autocomplete":"not","dense":"","clearable":"","rules":_vm.rules.required.concat(
                            _vm.rules.validDate,
                            new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                              new Date(_vm.parseDateToIso(_vm.fechaHastaSelected)) ||
                              'Formato incorrecto'
                          )},on:{"blur":function($event){_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaSelected)}},model:{value:(_vm.fechaHastaSelected),callback:function ($$v) {_vm.fechaHastaSelected=$$v},expression:"fechaHastaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaHasta),callback:function ($$v) {_vm.menuFechaHasta=$$v},expression:"menuFechaHasta"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaHastaSelected = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menuFechaHasta = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"pb-0 pt-5 text-right",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","elevation":"2","disabled":!_vm.isFormValid,"small":"","type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.logConfiguracionesHeaders,"items":_vm.logConfiguracionesItems,"item-key":"logConfigGeneralId","loading":_vm.loadingTable,"search":_vm.search,"show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
                          var headers = ref.headers;
                          var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('strong',[_vm._v("Datos:")]),_vm._v(" "+_vm._s(item.datos)+" ")])]}}])})],1)],1),(_vm.showIcon)?_c('v-col',{staticClass:"text-right pt-5 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }