<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplyed"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Tipo acción -->
                  <v-col cols="6" sm="6" md="3" class="py-0">
                    <v-autocomplete
                      v-model="tipoAccionSelected"
                      :items="tiposAccionesItems"
                      label="Tipo de acción"
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                      clearable
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="6" md="4" class="py-0">
                    <v-autocomplete
                      v-model="tipoConfiguracionSelected"
                      :items="tiposConfiguracionItems"
                      label="Tipo de configuración"
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                      :disabled="$route.params.tipoConfigId != null"
                      clearable
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- fecha desde -->
                  <v-col cols="6" md="3" class="py-0">
                    <v-menu
                      ref="fecha-desde"
                      v-model="menuFechaDesde"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          autocomplete="not"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          outlined
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menuFechaDesde = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- fecha hasta -->
                  <v-col cols="6" md="3" class="py-0">
                    <v-menu
                      ref="fecha-hasta"
                      v-model="menuFechaHasta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          outlined
                          autocomplete="not"
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menuFechaHasta = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="4" sm="6" class="pb-0 pt-5 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      elevation="2"
                      :disabled="!isFormValid"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="logConfiguracionesHeaders"
            :items="logConfiguracionesItems"
            item-key="logConfigGeneralId"
            :loading="loadingTable"
            class="elevation-1"
            :search="search"
            show-expand
            :expanded.sync="expanded"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Datos:</strong>
                {{ item.datos }}
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import Ayuda from "@/components/shared/Ayuda.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import { mask } from "vue-the-mask";

export default {
  name: "LogConfiguracionesGenerales",
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    Ayuda,
    GoBackBtn
  },
  data() {
    return {
      tipoAccionSelected: null,
      tiposAccionesItems: [],
      title: "Log de configuraciones generales",
      search: "",
      routeToGo: "ConfiguracionAdmSistema",
      showHelp: false,
      showIcon: true,
      searchIcon: enums.icons.SEARCH,
      showFilters: true,
      isFormValid: true,
      optionCode: enums.webSiteOptions.LOG_CONFIGURACIONES_GENERALES,
      calendarIcon: enums.icons.CALENDAR,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      showExpand: false,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      rules: rules,
      filtersApplyed: [],
      logConfiguracionesHeaders: [
        {
          text: "Usuario",
          align: "start",
          sortable: false,
          value: "usuario"
        },
        {
          text: "Fecha y hora",
          sortable: false,
          value: "fechaHora"
        },
        {
          text: "Tipo de configuración",
          sortable: false,
          value: "tipoConfiguracion"
        },
        {
          text: "Tipo acción",
          sortable: false,
          value: "tipoAccion"
        },
        { text: "", value: "data-table-expand" }
      ],
      logConfiguracionesItems: [],
      loadingTable: false,
      tiposConfiguracionItems: [],
      tipoConfiguracionSelected: null,
      expanded: [],
      menuFechaDesde: false,
      menuFechaHasta: false,
      fechaDesdeSelected: null,
      fechaHastaSelected: null,
      fechaDesde: null,
      fechaHasta: null
    };
  },
  async created() {
    await this.setSelects();
    this.getFechas();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    if (this.$route.params) {
      this.routeToGo = this.$route.params.routeToGo;
      this.tipoConfiguracionSelected = this.tiposConfiguracionItems.find(
        x => x.id === this.$route.params.tipoConfigId
      );
    }
    this.applyFilters();
  },
  methods: {
    ...mapActions({
      getLogConfiguracionesGenerales:
        "AdministracionSistema/getLogConfiguracionesGenerales",
      getLogConfiguracionGeneralTipo:
        "AdministracionSistema/getLogConfiguracionGeneralTipo",
      setAlert: "user/setAlert"
    }),
    getFechas() {
      let now = new Date();
      let año = now.getFullYear();
      let mes = now.getMonth() + 1;
      let dia = now.getDate();
      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes;
      let one = new Date(now.setMonth(now.getMonth() - 1));
      let oneAño = one.getFullYear();
      let oneMes = one.getMonth() + 1;
      let oneDia = one.getDate();
      oneDia < 10 ? (oneDia = `0${oneDia}`) : oneDia;
      oneMes < 10 ? (oneMes = `0${oneMes}`) : oneMes;
      this.fechaDesde = `${oneAño}-${oneMes}-${oneDia}`;
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      this.fechaHasta = `${año}-${mes}-${dia}`;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
    },
    async setSelects() {
      this.tiposAccionesItems = [
        { id: 1, value: "INSERTAR" },
        { id: 2, value: "EDITAR" },
        { id: 3, value: "ELIMINAR" }
      ];
      this.tiposConfiguracionItems = await this.getLogConfiguracionGeneralTipo();
    },
    async applyFilters() {
      this.loadingTable = true;
      this.showFilters = false;
      this.customizeFiltersApplied();

      try {
        const filters = {
          tipoAccion: this.tipoAccionSelected?.value,
          logConfiguracionTipoId: this.tipoConfiguracionSelected?.id,
          fechaDesde: this.fechaDesde,
          fechaHasta: this.fechaHasta
        };
        const response = await this.getLogConfiguracionesGenerales(filters);
        this.logConfiguracionesItems = response.data.data;
        this.expanded = this.logConfiguracionesItems;
      } catch {}

      this.loadingTable = false;
    },
    customizeFiltersApplied() {
      this.filtersApplyed = [];
      if (this.tipoAccionSelected == null) {
        this.filtersApplyed.splice(1, 1, {
          key: "noneFilters",
          label: "Tipo de acción",
          model: "Todas"
        });
      } else {
        if (this.tipoAccionSelected) {
          this.filtersApplyed.splice(1, 1, {
            key: "tipoAccionSelected.id",
            label: "Tipo de acción",
            model: this.tipoAccionSelected.value
          });
        }
      }
      if (this.tipoConfiguracionSelected == null) {
        this.filtersApplyed.splice(2, 1, {
          key: "noneTipoConfig",
          label: "Tipo de configuración",
          model: "Todos"
        });
      } else {
        if (this.tipoConfiguracionSelected) {
          this.filtersApplyed.splice(2, 1, {
            key: "tipoConfiguracionSelected.id",
            label: "Tipo de configuración",
            model: this.tipoConfiguracionSelected.value
          });
        }
      }
      if (this.fechaDesde) {
        this.filtersApplyed.splice(3, 1, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.formatDate(this.fechaDesde)
        });
      }
      if (this.fechaHasta) {
        this.filtersApplyed.splice(4, 1, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.formatDate(this.fechaHasta)
        });
      }
    },
    resetForm() {
      this.tipoAccionSelected = this.tipoConfiguracionSelected = null;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    }
  }
};
</script>

<style scoped></style>
